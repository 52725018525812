import React from 'react'
import Header from '../../Component/Header/Header'
import { BsFillFileLock2Fill, BsCurrencyExchange, BsRobot } from 'react-icons/bs'
import { MdOutlineCenterFocusStrong } from 'react-icons/md'
import { SiGooglecontaineroptimizedos } from 'react-icons/si'
import { AiFillCheckCircle } from 'react-icons/ai'
import { FaBorderAll } from 'react-icons/fa'
import { FaFolderClosed } from 'react-icons/fa6'
import { Tb24Hours } from 'react-icons/tb'
import Carousel from '../../Component/carousel/carousel.jsx'
import Footer from '../../Component/Footer'
import { useNavigate } from 'react-router-dom'
import './slider.scss'
export default function Home() {
    const navigate = useNavigate()
    return (
        <div>
            <Header />
            <div className='container'>
                <div className='row row-cols-lg-3 row-cols-1'>
                    <div className=' col '>
                        <div className='Head-Text'>TC XL350 Trading software</div>
                        <div className='mt-5 mb-5'>The TC XL350 software is programmed to auto trade which tells you when the market goes up for you to trade and make profit, it also tells you when not to trade so you wont loose money to your broker</div>
                        <button className='btn btn-dark mb-3' onClick={() => navigate('/form')}> Get Started</button>
                        <div className='row row-cols-3'>
                            <div className='col'>
                                <div className='row'>
                                    <div className='col-1 align-self-center'><BsFillFileLock2Fill /></div>
                                    <div className='col Head-Text_details'>Speed & Security</div>
                                </div>

                            </div>
                            <div className='col'>
                                <div className='row'>
                                    <div className='col-1 align-self-center'>
                                        <MdOutlineCenterFocusStrong />
                                    </div>
                                    <div className='col Head-Text_details'>
                                        Rigid Equity Protection
                                    </div>
                                </div>

                            </div>
                            <div className='col'>
                                <div className='row'>
                                    <div className='col-1 align-self-center'> <SiGooglecontaineroptimizedos /> </div>
                                    <div className='col Head-Text_details'> Optimized For All Market Conditions</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col'></div>
                    <div className='col mt-5 pt-5  '>
                        <div className='col'>
                            <img src='cell.jpg' height={300} alt='cell' style={{ width: '100%' }} />
                        </div>
                        <div className='mb-5'>
                            <div className='sub-head mt-2 mb-4 text-center'>"Achieving Automated Trading Excellence"</div>


                            Everything we do is suited towards enabling our traders to increase

                            their daily, weekly, monthly gains, minimize drawdown whilst providing a hands-free service that anyone can use, regardless of their trading knowledge. We equip traders with unique softwares for personal capital as well as funded capital management.
                        </div>
                    </div>

                </div>
            </div>
            <div className='main-content'>
                <div className='container text-white'>
                    <div className='row row-cols-lg-2 row-cols-1'>
                        <div className='col align-self-center text-center' data-aos="fade-right">
                            <div className='main-content_text mb-2'>With the Right Software, </div>
                            <div className='main-content_text'>Great Things Can Happen</div>
                        </div>
                        <div className='col p-5' data-aos="fade-left">
                            <div className='m-3' > Our Expert Advisors have been designed to create a renewable method of generating sustainable ROI (return on investment) with capital sourced from either proprietary trading firms (“i.e binary.com, Pocket Option, Close Option, Kraken, coinbase, FTMO etc) or personal capital “.
                            </div>


                            <div className='m-3'>
                                Our market research has shown that the majority of the automated trading softwares currently available to the public, operate on subpar systems such as martingale, grid, and arbitrage which all fail to function on a long-term basis.
                            </div>
                            <div className='m-3'>
                                With TXTS, we have a consistent way of generating returns that are produced in a highly calculated manner with strict drawdown protocols as well as our venerable programmable equity protector system that we have used for over 10 years.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='col mt-5'>

                    <div className=' main-content_text'> What We Offer</div>
                    <div className='mt-3 mb-3'> At TXTS we offer our traders 2 programs tailored for their trading needs.</div>



                    <div className='mt-3 mb-3'>1. Get You Funded Program</div>



                    <div className='mt-3 mb-3'>2. Personal BOT Program</div>
                </div>

                <div className='row row-cols-lg-3 row-cols-1 mb-5'>
                    <div className='bg-lig col main-content_box '>
                        <div className='text-center '><h1 className='main-content--box__Icon'><AiFillCheckCircle /></h1></div>
                        <div className='text-center fw-bold'>GET YOU FUNDED</div>
                        <div className='m-2' data-aos="zoom-in">Let our team of professional expert traders pass and manage your prop trading firm account of your choice.
                            <div>
                                For pricing and availability please message <a href='https://instagram.com/tcxl350tradingsoftware?igshid=OGQ5ZDc2ODk2ZA==' className='text-black fw-bold'>@tcxl350tradingsoftware on Instagram</a>
                            </div>
                            <button className='btn btn-dark fw-bold' onClick={() => navigate('/form')}>Starting $1999</button>
                        </div>
                    </div>
                    <div className='col main-content text-white main-content_box align-content-center'>
                        <div className='text-center '><h1 className='main-content--box__Icon'>< BsCurrencyExchange /></h1></div>

                        <div className='text-center fw-bold'>HFT- ARBITRAGE LICENSE</div>
                        <div className='m-2' data-aos="zoom-in">This elite EXPERT ADVISOR High Frequency Trading system, allows you to pass any prop firm challenge program within a short period of time through a series of hyper scalping trades. The system is installed on a VPS (Virtual Private Server). with 1 Leg, 2 Leg, Multileg Arbitrage strategies on XAUUSD, EURUSD and US30 index instruments. This program comes equipped with a fully programmable equity guardian, and customizable lot sizes. Traders can utilize this system for for personal or prop firm capital (both passing and managing the account).
                            <div>
                                For pricing and availability please message <a href='https://instagram.com/tcxl350tradingsoftware?igshid=OGQ5ZDc2ODk2ZA==' className='text-white fw-bold'>@tcxl350tradingsoftware on Instagram</a>
                            </div>
                            <button className='btn btn-light fw-bold' onClick={() => navigate('/form')}>Starting $1999</button>
                        </div>
                    </div>
                    <div className='bg-lig col main-content_box'>
                        <div className='text-center '><h1 className='main-content--box__Icon'>< BsRobot /></h1></div>
                        <div className='text-center fw-bold'>PERSONAL TXTS BOT</div>
                        <div className='m-2' data-aos="zoom-in">This expert advisor permits one to trade GOLD, US30, and mixed currency pairs during all 3 sessions (including Asia- Pacific, London, and New York.) This system comes equipped with customizable equity guardian, adaptive lot sizes, and news filter with a direct data feed from Bloomberg News. Traders can employ this algorithm on their personal account as well as prop firms (both passing and managing the account).

                            <div>
                                For pricing and availability please message <a href='https://instagram.com/tcxl350tradingsoftware?igshid=OGQ5ZDc2ODk2ZA==' className='text-black fw-bold'>@tcxl350tradingsoftware on Instagram</a>
                            </div>
                            <button className='btn btn-dark fw-bold' onClick={() => navigate('/form')}>Starting $1999</button>
                        </div>
                    </div>
                </div>

                {/* ######## */}

                <div className='row row-cols-lg-2 row-cols-1 mt-4'>
                    <div className='col-lg-4 col d-flex justify-content-center m-lg-5'>
                        <img src='insta.jpg' height={200} style={{ width: '50%' }} alt='logo' />
                    </div>
                    <div className='col m-lg-5 mt-5 '>
                        <div className=' d-flex justify-content-center'>
                            <div className='main-content_text  w-75 '>JOIN THE FASTEST GROWING PLATFORM</div></div>
                        <div className=' d-flex justify-content-center'>
                            <div className='mt-3 w-75'>Follow us on our Instagram via <a href='https://instagram.com/tcxl350tradingsoftware?igshid=OGQ5ZDc2ODk2ZA==' className='text-black '>@tcxl350tradingsoftware</a>. By joining, you will find a plethora of posts including challenge passes, updates to our trading technologies, as well as verified track records that our members can use to view the performance of our trading systems.</div>
                        </div>
                        <div className='d-flex justify-content-center mt-3'>
                            <div className='row row-cols-3 w-75'>
                                <div className='col'>
                                    <div className='row row-cols-2'>
                                        <div className='col-1'>
                                            < FaBorderAll />
                                        </div>
                                        <div className='col  Head-Text_details'>
                                            <div>Compact hub</div>

                                        </div>
                                    </div>

                                </div>
                                <div className='col'>
                                    <div className='row row-cols-2'>
                                        <div className='col-1'>
                                            < FaFolderClosed />
                                        </div>
                                        <div className='col  Head-Text_details'>
                                            Integrated
                                            File Sharing
                                        </div>
                                    </div>


                                </div>
                                <div className='col'>
                                    <div className='row row-cols-2'>
                                        <div className='col-1'>
                                            < Tb24Hours />
                                        </div>
                                        <div className='col  Head-Text_details'>
                                            24/7 Support
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='Explore'>
                <div className='container'>
                    <div className='row row-cols-lg-2 row-cols-1'>
                        <div className='col align-self-center mt-4'>

                            <div className='main-content_text mt-3'>Explore Some Of Our Success Stories</div>
                            <div className='mt-3'>Many members have been funded with proprietary trading firm capital thanks to TXTS revolutionary trading systems. Following the privacy rules, the names of successful users have been redacted from the traders' certificates posted. For pricing and availability please message <a href='https://instagram.com/tcxl350tradingsoftware?igshid=OGQ5ZDc2ODk2ZA==' className='text-white fw-bold '>@tcxl350tradingsoftware</a> on Instagram</div>
                            <button className='btn btn-light ' onClick={() => navigate('/form')}>Get Started</button>
                        </div>
                        <div className='col mt-5 mb-5 '>
                            <Carousel />
                        </div>
                    </div>
                </div>
            </div>
            <div className='Trust mt-5'>
                <div className='container pt-5 pb-5 '>
                    <div className='row row-cols-lg-2 row-cols-1'>
                        <div className='col'><div className='main-content_text mb-5 '>Trusted Among Industry Leaders</div>
                            <div className='col-8'>
                                Thanks to TXTS rigorous algorithmic trading protocols, we have consistently been the top choice for getting traders funded. Our easy to use, highly adaptable EA's have allowed users to pass all sorts of challenges, and obtain multiple 7 figures of funded trading capital. The unique programming of our softwares, permits traders to use our system in managing funding capital for as long as they wish.
                            </div>


                            <div className='col mt-5'>
                                Don't see your selected proprietary trading firm? Our Expert Advisor systems can still pass and manage them. Simply enquire via our 24/7 support team, and our traders will be able to assist you.
                            </div>
                            <div class="slider">
                                <div class="slide-track">
                                    <div class="slide">
                                        <img src="ftmo.webp" height="100" width="250" alt="" />
                                    </div>
                                    <div class="slide">
                                        <img src="e8.webp" height="100" width="250" alt="" />
                                    </div>
                                    <div class="slide">
                                        <img src="nova.webp" height="100" width="250" alt="" />
                                    </div>
                                    <div class="slide">
                                        <img src="trust.webp" height="100" width="250" alt="" />
                                    </div>
                                    <div class="slide">
                                        <img src="binarycom.jpg" height="100" width="250" alt="" />
                                    </div>
                                    <div class="slide">
                                        <img src="coinbase.jpg" height="100" width="250" alt="" />
                                    </div>
                                    <div class="slide">
                                        <img src="kraken.jpg" height="100" width="250" alt="" />
                                    </div>
                                    <div class="slide">
                                        <img src="pocket.jpg" height="100" width="250" alt="" />
                                    </div>
                                    <div class="slide">
                                        <img src="cie.jpg" height="100" width="250" alt="" />
                                    </div>
                                    
                                </div>
                            </div>

                        </div>
                        <div className='col'></div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}
