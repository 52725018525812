import React from 'react';
import Slider from 'react-slick';
import './carousel.css';

import { Box, Flex, } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

const Carousel = () => {
    
    const isMobile = useMediaQuery({ maxWidth: '750px' });
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: isMobile ? 1 : 1,
        slidesToScroll: 1,
        centerMode: false,

    };

    return (
        <Slider {...settings} className='container bg-dark' >
            {/* Render your carousel slides here */}


            <Box className='container rounded-4 ' maxH='2vh' >
                <Flex justifyContent={'center'} className="b-img">
                    <img src='adm.png' alt='img' width={200} height={200} />
                </Flex>
            </Box>
            <Box className='container' maxH='2vh' >
                <Flex justifyContent={'center'} className="b-img" >
                    <img src='cdm.jpg' alt='img' width={200} height={200} />
                </Flex>
            </Box>
            <Box className='container' maxH='2vh' >
                <Flex justifyContent={'center'} className="b-img">
                    <img src='odm.jpg' alt='img' width={200} height={200} />
                </Flex>
            </Box>
            <Box className='container' maxH='2vh' >
                <Flex justifyContent={'center'} className="b-img">
                    <img src='4in4.png' alt='img' width={200} height={200} />
                </Flex>
            </Box>
            <Box className='container' maxH='2vh' >
                <Flex justifyContent={'center'} className="b-img">
                    <img src='free.jpg' alt='img' width={200} height={200} />
                </Flex>
            </Box>
            <Box className='container' maxH='2vh' >
                <Flex justifyContent={'center'} className="b-img">
                    <img src='crypto.jpeg' alt='img' width={200} height={200} />
                </Flex>
            </Box>
        </Slider>
    );
};

export default Carousel;
