import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer'

export default function ProjectForm() {
    const [resDate, setResDate] = useState('')
    const [error, setError] = useState('')
    const [payement, setPayment] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const UserData = (e) => {
        const value = e.target.value
        setResDate({
            ...resDate,
            [e.target.name]: value
        }
        )
    }
    const SubmitForm = (e) => {
        e.preventDefault()
    }
    useEffect(() => {
        const timer = setTimeout(() => {
            setPayment(true)
        }, 10000)
        return () => {
            clearTimeout(timer)
        }
    })
    useEffect(() => {
        if (resDate.membername === '' || resDate.membername === undefined) {
            setError('Field must not be empty ')
        } else if (resDate.memberemail === '' || resDate.memberemail === undefined) {
            setError('Field must not be empty ')
        } else if (resDate.membercountry === '' || resDate.membercountry === undefined) {
            setError('Field must not be empty ')
        }

        else if (resDate.reason === '' || resDate.reason === undefined) {
            setError('Field must not be left empty ')
        } else {
            setIsSubmit(true)
        }
        console.log('HHH', resDate)
    }, [resDate])
    return (
        <div>
            <Header />
            <hr />
            <div className='container'>
                <div className='d-flex justify-content-center'>
                    <form className='w-75 w-lg-50 mt-5 mb-5'>
                        Fill the form to Register
                        <div className='form-group m-3'>
                            <label className='form-label'>Name</label>
                            <input name='membername' type='text' placeholder='Name' onChange={UserData} required className='form-control' />
                            <div className='Head-Text_details text-danger'>{isSubmit ? '' : error}</div>
                        </div>
                        <div className='form-group m-3'>
                            <label className='form-label'>Email</label>
                            <input name='memberemail' type='email' placeholder='Email' onChange={UserData} className='form-control' />
                            <div className='Head-Text_details text-danger'>{isSubmit ? '' : error}</div>
                        </div>
                        <div className='form-group m-3'>
                            <label className='form-label'>Country</label>
                            <input name='membercountry' type='text' placeholder='Country' onChange={UserData} className='form-control' />
                            <div className='Head-Text_details text-danger'>{isSubmit ? '' : error}</div>
                        </div>
                        <div className='form-group m-3'>
                            <label className='form-label'>Register as</label>
                            <select className='form-select' name='reason' onChange={UserData}>
                                <option value={''}>--Select--</option>
                                <option value={'individual'}> An Individual or individuals
                                </option>
                                <option value={'company'}> Company (Corporation, financial institution, etc)
                                </option>
                                <option value={'self'}>Self-Directed IRS or 401(k)</option>
                            </select>
                            <div className='Head-Text_details text-danger'>{isSubmit ? '' : error}</div>
                        </div>
                        <div className='m-3'>
                            You are expect to make an Amount: <strong>$1999</strong> in BTC to the wallet Below
                        </div>

                        <div className='d-flex justify-content-center'>
                            {
                                isSubmit ? <button className='btn btn-dark' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={SubmitForm}>Submit</button> : <button className='btn btn-dark' disabled onClick={SubmitForm}>Submit</button>
                            }

                        </div>

                    </form>
                </div>

            </div>
            <hr />
            <Footer />
            <div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">

                        <div class="modal-body text-center">
                            <div className='main-content_text'>User Information</div>
                            <div className='text-start'> 
                                <div className='mt-5 mb-5'>
                                    <strong>Name:</strong>  {resDate.membername}
                                </div>
                                <div className='mt-5 mb-5'>
                                    <strong>Email:</strong> {resDate.memberemail}
                                </div>
                                <div className='mt-5 mb-5'>
                                    <strong>Country:</strong> {resDate.membercountry}
                                </div>
                            </div>

                            <hr />
                            <div className='mt-5 mb-5'>
                                Kindly make a Payment of <strong>$1499 in BTC</strong> to the address Listed below
                            </div>
                            <div>
                                <img src='jpg' width={'200px'} height={'200px'} alt='icon' />
                            </div>
                            <div className='mt-5 mb-5 fw-bold'>
                                3FqLXJvFourhzazb4t6PiyjKjy1T7AoVRn
                            </div>

                            <div className='text-danger Head-Text_details'>
                                Kindly Upload Payment Screen to Our Instagram Page.
                                Ensure you've made payment before making upload.
                                Ensure you're Sending crypto to the right address as
                                failure to do so could lead loss of crypto
                            </div>
                            <div className='mb-3 mt-3'>
                                {
                                    payement ? <a href='https://instagram.com/tcxl350tradingsoftware?igshid=OGQ5ZDc2ODk2ZA==' alt='link'><button className='btn btn-success' >I have made Payment</button></a> : <button className='btn btn-success' disabled>I have made Payment</button>
                                }
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
