import React from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import './header.css'
import { useLocation, useNavigate } from 'react-router-dom'
export default function Header({ color, states }) {
    const navigate = useNavigate()
    const history = useLocation()
    const location = history.pathname

    return (
        <>
            <nav className={`navbar navbar-expand-lg text-white d-lg-block d-none ${color}`}>
                <div className='container'>
                    <div className='row' onClick={() => navigate('/')} type='button'>
                        <div className='navbar-brand rounded-3 col-1'> <img src='logo.jpg' width={40} height={40} alt='logo' className='rounded-circle' /></div>
                        <div className='align-content-center text-dark me-3 col mt-3'>
                            <div className='col fw-bold'>TXTS</div>
                            <div className='col mantra-info'>TC XL350 Trading software</div>
                        </div>
                    </div>

                    <div>
                        <ul className='navbar-nav text-dark nav-sized'>
                            <li className={`navbar-link m-2  ${location === '/' ? 'active' : ''} `} onClick={() => (navigate('/'))} type='button'>Home</li>
                            <li className={`navbar-link m-2  ${location === '/about' ? 'active' : ''} `} onClick={() => (navigate('/about'))} type='button'>About</li>

                            <li className='navbar-link btn-dark-mode   btn btn-dark' onClick={() => { navigate('/form') }} >Get Started</li>
                        </ul>
                    </div>
                </div>
            </nav>
            <nav className={`navbar navbar-expand-lg text-white d-lg-none d-block ${color}`}>
                <div className='container'>
                    <div> <button className='navbar-toggle btn btn-none text-black fs-1' type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample"><AiOutlineMenu /></button> </div>

                    <div className='navbar-brand bg-dark rounded-3'> <img src='logo.jpg' width={40} height={30} alt='logo' /></div>

                </div>
                <div className="offcanvas offcanvas-start canvas-bg w-75" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                    <div className="offcanvas-header bg-white header-style">


                        <div className='col fw-bold text-black'>TXTS</div>
                        <div className='col mantra-info text-black'>TC XL350 Trading software</div> <img src='logo.jpg' alt='logo' width={40} height={30} />
                    </div>
                    <div className="offcanvas-body">
                        <div>
                            <ul className='navbar-nav'>
                                <li className={`navbar-link m-2 border-nav  ${location === '/' ? 'active' : ''} `} onClick={() => (navigate('/'))} type='button'>Home</li>
                                <li className={`navbar-link m-2 border-nav  ${location === '/about' ? 'active' : ''} `} onClick={() => (navigate('/about'))} type='button'>About</li>
                                <li className={`navbar-link m-2 border-nav  ${location === '/form' ? 'active' : ''} `} onClick={() => { navigate('/form') }}  type='button'>Get Started</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </nav>

        </>
    )
}
