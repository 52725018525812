import React from 'react'
import Header from '../../Component/Header/Header'
import Footer from '../../Component/Footer'

export default function About() {
    return (
        <div>
            <Header />
            <hr />
            <div className='container'>
                <div className='mt-3 mb-3 text-center main-content_text'>
                    ABOUT
                </div>
                <div className='row row-cols-lg-2 row-cols-1' data-aos="zoom-in">
                    <div className='col'>
                        <div>The TC XL350 software established in 2016, is programmed to auto trade which tells you when the market goes up for you to trade and make profit, it also tells you when not to trade so you wont loose money to your broker. The software auto trades that means you can set it on automatic or manual like you have settings option on you smart phone. If for instance I make 12 trades daily with the software I’m sure of winning at least 9.</div>

                        <div className='mt-3'>
                            We have all seen an array of poor martingale systems, grid and even arbitrage that do not function or rather blow accounts.
                        </div>
                        <div className='mt-3'>
                            With TC XL350 we have a consistent way to generate returns that are generated in a drawdown friendly manner with proper risk application depending on capital allocation.
                        </div>
                        <div className='mt-3'>
                            TC XL350 has been in development for the past 6 years and has been proven to reliably pass challenges, manage personal capital and more! This page will be used to share its performance, clients results and most importantly, how to get access to these trading technologies!
                        </div>

                        <div className='mt-3'>
                            The TC XL350 is a trading software that facilitates the trading and analysis of financial products, such as stocks, options, digital currencies , or currencies. We use that provide to place trades and manage accounts to get an average of 7/10 winnings per trade.
                        </div>
                    </div>

                </div>
                <div className='col main-content_text mt-5'>
                    
                </div>
                <div className='row row-cols-lg-2 row-cols-1'>
                    <div className='col mt-4 ' data-aos="zoom-in-up">
                        <div className='justify-content-center d-flex'>
                            <img src='ceo.jpg' alt='ceo' className='border rounded-3 shadow' height={300} style={{ width: '50%' }} />
                        </div>

                        <div className='text-center mt-3'>MEET OUR FOUNDER: Christopher Daniel</div>
                        <div className='justify-content-center d-flex mt-5'>
                            <div>Christopher Daniel has extensive experience in crypto investment banking, crypto development and investor relationships. With his focused mindset on high profits and big marges, Christopher has grown multiple companies from $0 to $100m in revenue.</div>
                        </div>
                    </div>
                    <div className='col mt-4' data-aos="zoom-in-up">
                        <div className='justify-content-center d-flex'>
                            <img src='aceo.jpg' alt='ceo' className='border rounded-3 shadow' height={300} style={{ width: '50%' }} />
                        </div>

                        <div className='text-center mt-3'>MEET OUR CO-FOUNDER: Alesia Taylor</div>
                        <div className='justify-content-center d-flex mt-5'>
                            <div> Alessia Taylor has served as our co-founded based in Dubai who has worked with s some of Silicon Valley's largest Tech companies. She is also cryptocurrency and binary options expert with 7+ years’ of experience. Most recently she built all Sales (OTC Desk), marketing, and customer experience processes. She is also responsible for delivering strategy and direction at TXTS and scaling the business through operational excellence.</div>
                        </div>
                    </div>
                </div>


            </div>
            <hr />
            <Footer />
        </div>
    )
}
