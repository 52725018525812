import { Routes, Route } from "react-router-dom";
import Home from "./Pages/home/Home";
import About from "./Pages/about/about";
import Form from './Component/form/form'
function App() {
  return (
    <div className="App" style={{overflowX: 'hidden'}}>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/form' element={<Form />} />



      </Routes>
    </div>
  );
}

export default App;
